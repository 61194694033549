import * as React from "react";

export const Root = React.lazy(() => import("components/root/Root"));
export const RequireLayout = React.lazy(
  () => import("router/requireLayout/RequireLayout")
);
export const Layout = React.lazy(() => import("components/layout/Layout"));
export const Home = React.lazy(() => import("components/home/Home"));
export const OurShop = React.lazy(() => import("components/shop/OurShop"));
export const AboutUS = React.lazy(() => import("components/aboutus/AboutUS"));
export const OurLocation = React.lazy(() => import("components/location/OurLocation"));
export const Location = React.lazy(() => import("components/home/location/Location"));
export const Service = React.lazy(() => import("components/service/OurService"));
export const Career = React.lazy(() => import("components/form/career/Career"));
export const EyebrowConsentForm = React.lazy(() => import("components/form/Consent/EyebrowConsentForm"));
export const FacialConsentForm = React.lazy(() => import("components/form/Consent/FacialConsentForm"));
export const WaxingConsentForm = React.lazy(() => import("components/form/Consent/WaxingConsentForm"));
export const Reviews = React.lazy(() => import("components/review/Review"));
export const ServiceListing = React.lazy(() => import("components/service/OurServiceListing"));








export const SpecialBooking = React.lazy(() => import("components/form/specialBooking/SpecialBooking"));
export const Booking = React.lazy(() => import("components/form/booking/Booking"));
export const SpecialListing = React.lazy(() => import("components/special/SpecialListing"));








export const Login = React.lazy(() => import("components/auth/login/Login"));
export const Register = React.lazy(
  () => import("components/auth/register/Register")
);

export const ForgotPassword = React.lazy(
  () => import("components/auth/forgotPassword/ForgotPassword")
);
export const ResetPassword = React.lazy(
  () => import("components/auth/resetPassword/ResetPassword")
);
export const VerifyEmail = React.lazy(
  () => import("components/auth/verifyEmail/VerifyEmail")
);
export const Product = React.lazy(
  () => import("components/shop/product/Product")
);
export const ProductsByCategory = React.lazy(
  () => import("components/shop/productsByCategory/ProductsByCategory")
);
export const ProductsByBrand = React.lazy(
  () => import("components/shop/productsByBrand/ProductsByBrand")
);
export const DealProducts = React.lazy(
  () => import("components/shop/dealProducts/DealProducts")
);
export const FeaturedProducts = React.lazy(
  () => import("components/shop/featuredProducts/FeaturedProducts")
);
export const NonBeautyProducts = React.lazy(
  () => import("components/shop/nonBeautyProducts/NonBeautyProducts")
);
export const SearchProducts = React.lazy(
  () => import("components/shop/searchProducts/SearchProducts")
);
export const RecommendationProducts = React.lazy(
  () => import("components/shop/recommendationProducts/RecommendationProducts")
);
export const LatestProducts = React.lazy(
  () => import("components/shop/latestProducts/LatestProducts")
);

export const SuspenseLoading = React.lazy(
  () => import("components/loading/suspenseLoading/SuspenseLoading")
);

export const Gallery = React.lazy(() => import("components/aboutus/gallery/Gallery"));
export const Contact = React.lazy(
  () => import("components/pages/contact/Contact")
);


export const Brands = React.lazy(() => import("components/shop/brands/Brands"));
export const Page = React.lazy(() => import("components/misc/page/Page"));
export const Faqs = React.lazy(() => import("components/misc/faq/Faq"));


export const Blogs = React.lazy(() => import("components/misc/blogs/Blogs"));
export const Blog = React.lazy(() => import("components/misc/blogs/Blog"));
export const NotFound = React.lazy(
  () => import("components/pages/notFound/NotFound")
);
export const ErrorFallback = React.lazy(
  () => import("components/errorFallback/ErrorFallback")
);

// User routes start
export const RequireUser = React.lazy(
  () => import("router/requireUser/RequireUser")
);
export const UserCartCheckout = React.lazy(
  () => import("components/user/cart/CartCheckout")
);
export const UserStripePayment = React.lazy(
  () => import("components/user/cart/stripePayment/StripePayment")
);
export const PaymentConfirm = React.lazy(
  () => import("components/user/cart/paymentConfirm/PaymentConfirm")
);

export const UserWishlist = React.lazy(
  () => import("components/user/wishlist/Wishlist")
);
export const UserDashboard = React.lazy(
  () => import("components/user/dashboard/Dashboard")
);
export const UserProfile = React.lazy(
  () => import("components/user/dashboard/profile/Profile")
);
export const UserAddressBook = React.lazy(
  () => import("components/user/dashboard/addressBook/AddressBook")
);

export const UserOrders = React.lazy(
  () => import("components/user/dashboard/orders/Orders")
);

export const UserReviews = React.lazy(
  () => import("components/user/dashboard/reviews/Reviews")
);
export const UserChangePassword = React.lazy(
  () => import("components/user/dashboard/changePassword/ChangePassword")
);
// User routes end

// Admin routes start
export const RequireAdmin = React.lazy(
  () => import("router/requireAdmin/RequireAdmin")
);
export const AdminProfile = React.lazy(
  () => import("components/admin/profile/Profile")
);
export const AdminChangePassword = React.lazy(
  () => import("components/admin/changePassword/ChangePassword")
);
export const AdminDashboard = React.lazy(
  () => import("components/admin/dashboard/Dashboard")
);
export const AdminService = React.lazy(
  () => import("components/admin/services/Services")
);
export const AdminServicePricing = React.lazy(
  () => import("components/admin/servicePricing/ServicePricing")
);
export const AdminSpecial = React.lazy(
  () => import("components/admin/special/Special")
);
export const AdminSliders = React.lazy(
  () => import("components/admin/sliders/Sliders")
);
export const AdminCategories = React.lazy(
  () => import("components/admin/categories/Categories")
);
export const AdminBrands = React.lazy(
  () => import("components/admin/brands/Brands")
);
export const AdminColors = React.lazy(
  () => import("components/admin/colors/Colors")
);
export const AdminDeliveryAddresses = React.lazy(
  () => import("components/admin/deliveryAddresses/DeliveryAddresses")
);
export const AdminVouchers = React.lazy(
  () => import("components/admin/vouchers/Vouchers")
);
export const AdminProducts = React.lazy(
  () => import("components/admin/products/Products")
);
export const AdminReviews = React.lazy(
  () => import("components/admin/reviews/Reviews")
);
export const AdminOrders = React.lazy(
  () => import("components/admin/orders/Orders")
);
export const AdminSpecialBooking = React.lazy(
  () => import("components/admin/booking/special/SpecialBooking")
);
export const AdminBooking = React.lazy(
  () => import("components/admin/booking/normalBooking/Booking")
);
export const AdminCareer = React.lazy(
  () => import("components/admin/career/Career")
);
export const AdminConsent = React.lazy(
  () => import("components/admin/consent/Consent")
);
export const AdminViewConsent = React.lazy(
  () => import("components/admin/consent/ViewConsent")
);
export const AdminProductForm = React.lazy(
  () => import("components/admin/products/form/ProductForm")
);
export const AdminServiceForm = React.lazy(
  () => import("components/admin/services/form/ServiceForm")
);

export const AdminUsersUsers = React.lazy(
  () => import("components/admin/users/users/Users")
);

export const AdminUsersAdmins = React.lazy(
  () => import("components/admin/users/admins/Admins")
);
export const AdminSiteSetting = React.lazy(
  () => import("components/admin/misc/siteSetting/SiteSetting")
);
export const AdminContactMessages = React.lazy(
  () => import("components/admin/misc/contactMessages/ContactMessages")
);
export const AdminHomepageAds = React.lazy(
  () => import("components/admin/misc/homeAds/HomeAds")
);
export const AdminEmailTemplates = React.lazy(
  () => import("components/admin/misc/emailTemplates/EmailTemplates")
);
export const AdminEmailTemplate = React.lazy(
  () => import("components/admin/misc/emailTemplates/form/EmailTemplateForm")
);

export const AdminPages = React.lazy(
  () => import("components/admin/misc/pages/Pages")
);
export const AdminPage = React.lazy(
  () => import("components/admin/misc/pages/form/PageForm")
);
export const AdminFaqs = React.lazy(
  () => import("components/admin/misc/faqs/Faqs")
);
export const AdminFaqGroup = React.lazy(
  () => import("components/admin/misc/faqGroup/FaqGroup")
);
export const AdminSubscriber = React.lazy(
  () => import("components/admin/misc/subscriber/Subscriber")
);
export const AdminGallery = React.lazy(
  () => import("components/admin/misc/gallery/Gallery")
);
export const AdminLocation = React.lazy(
  () => import("components/admin/misc/location/Location")
);
export const AdminLocationForm = React.lazy(
  () => import("components/admin/misc/location/form/LocationForm")
);

export const AdminBlogs = React.lazy(
  () => import("components/admin/blogs/Blogs")
);
export const AdminBlogForm = React.lazy(
  () => import("components/admin/blogs/form/BlogForm")
);

export const AdminTeams = React.lazy(
  () => import("components/admin/aboutUs/teams/Teams")
);
export const AdminTestimonials = React.lazy(
  () => import("components/admin/aboutUs/testimonials/Testimonials")
);
// Admin routes end
