import { createBrowserRouter } from "react-router-dom";

import {
  // Public routes start
  Root,
  // RequireLayout,
  Layout,
  Home,
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  Product,
  ProductsByCategory,
  ProductsByBrand,
  DealProducts,
  FeaturedProducts,
  SearchProducts,
  RecommendationProducts,
  LatestProducts,

  SuspenseLoading,

  Contact,
  Brands,
  Page,
  Faqs,
  Blogs,
  Blog,
  NotFound,
  ErrorFallback,
  // Public routes end

  // User routes start
  RequireUser,
  UserCartCheckout,
  UserWishlist,
  UserDashboard,
  UserProfile,
  UserAddressBook,

  UserOrders,
  UserReviews,
  UserChangePassword,
  // User routes end

  // Admin routes start
  RequireAdmin,
  AdminProfile,
  AdminChangePassword,
  AdminDashboard,
  AdminSliders,
  AdminCategories,
  AdminBrands,
  AdminColors,
  AdminDeliveryAddresses,
  AdminVouchers,
  AdminProducts,
  AdminReviews,
  AdminOrders,

  AdminProductForm,
  AdminUsersUsers,
  AdminUsersAdmins,
  AdminSiteSetting,
  AdminContactMessages,
  AdminHomepageAds,
  AdminEmailTemplates,
  AdminEmailTemplate,

  AdminPages,
  AdminPage,
  AdminFaqs,
  AdminBlogs,
  AdminBlogForm,
    AdminTeams,
  AdminTestimonials,
  AdminService,
  AdminServiceForm,
  OurShop,
  AboutUS,
  UserStripePayment,
  PaymentConfirm,
  AdminGallery,
  Gallery,
  AdminLocation,
  AdminLocationForm,

  Location,
  AdminServicePricing,
  AdminSpecial,
  OurLocation,
  Service,
  SpecialBooking,
  AdminFaqGroup,
  Career,
  Booking,
  AdminSpecialBooking,
  AdminBooking,
  EyebrowConsentForm,
  FacialConsentForm,
  WaxingConsentForm,
  AdminCareer,
  AdminConsent,
  SpecialListing,
  Reviews,
  AdminViewConsent,
  ServiceListing,
  NonBeautyProducts,
  AdminSubscriber,
  // Admin routes end
} from "./imports";
import BestSellerProducts from "components/shop/bestSellerProducts/BestSellerProducts";


const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    errorElement: <ErrorFallback />,
    children: [
      {
        path: "",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: "/ourshop",
            element: <OurShop />,
          },
          {
            path: "/location",
            element: <Location count={5} />,
          },
          {
            path: "/location/:slug",
            element: <OurLocation/>,
          },
          {
            path: "/service",
            element:<ServiceListing/>
          },
          {
            path: "/service/:slug",
            element:<Service/>
          },
          {
            path: "/aboutus",
            element: <AboutUS />,
          },
          {
            path: "/special",
            element: <SpecialListing />,
          },
          {
            path: "/career",
            element: <Career />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "register",
            element: <Register />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },

          {
            path: "reset-password/:token",
            element: <ResetPassword />,
          },
          {
            path: "verify-email/:token",
            element: <VerifyEmail />,
          },

          {
            path: "product/:slug",
            element: <Product />,
          },
          {
            path: "product/:slug/:refCode",
            element: <Product />,
          },
          {
            path: "category/:slug",
            element: <ProductsByCategory />,
          },
          {
            path: "brand/:slug",
            element: <ProductsByBrand />,
          },
          {
            path: "products/deals",
            element: <DealProducts />,
          },
          {
            path: "products/featured",
            element: <FeaturedProducts />,
          },
          {
            path: "products/nonBeauty",
            element: <NonBeautyProducts />,
          },
          {
            path: "products/bestseller",
            element: <BestSellerProducts />,
          },
          {
            path: "products/search/:keyword",
            element: <SearchProducts />,
          },
          {
            path: "products/recommendation",
            element: <RecommendationProducts />,
          },
          {
            path: "products/latest",
            element: <LatestProducts />,
          },

          {
            path: "suspense-loading",
            element: <SuspenseLoading />,
          },
        
          {
            path: "gallery",
            element: <Gallery />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
          {
            path: "brands",
            element: <Brands />,
          },
          {
            path: "privacy-policy",
            element: <Page slug="privacy-policy" />,
          },
          {
            path: "terms-and-conditions",
            element: <Page slug="terms-and-conditions" />,
          },
          {
            path: "consent-terms-and-conditions",
            element: <Page slug="consent-terms-and-conditions" />,
          },
          {
            path: "faq",
            element: <Faqs />,
          },
          {
            path: "blog",
            element: <Blogs />,
          },
          {
            path: "blog/:slug",
            element: <Blog />,
          },
          {
            path: "Booking",
            element: <Booking />,
          },
          {
            path: "specialBooking",
            element: <SpecialBooking />,
          },
          {
            path: "eyebrowConsent",
            element: <EyebrowConsentForm />,
          },
          {
            path: "review",
            element: <Reviews />,
          },
          {
            path: "facialConsent",
            element: <FacialConsentForm />,
          },
          {
            path: "waxingConsent",
            element: <WaxingConsentForm />,
          },
        ],
      },
      {
        path: "user/cart/PaymentConfirm",
        element: <PaymentConfirm/>,
      },
      {
        path: "user",
        element: <RequireUser />,
        children: [
          {
            path: "cart/checkout",
            element: <UserCartCheckout />,
          },
          {
            path: "cart/StripePayment",
            element: <UserStripePayment />,
          },
         
          {
            path: "wishlist",
            element: <UserWishlist />,
          },
          {
            path: "profile",
            children: [
              {
                path: "",
                element: <UserDashboard />,
              },
              {
                path: "information",
                element: <UserProfile />,
              },
              {
                path: "address",
                element: <UserAddressBook />,
              },
           
              {
                path: "orders",
                element: <UserOrders />,
              },
              {
                path: "reviews",
                element: <UserReviews />,
              },
              {
                path: "change-password",
                element: <UserChangePassword />,
              },
            ],
          },
        ],
      },

      {
        path: "admin",
        element: <RequireAdmin />,
        children: [
          {
            path: "",
            element: <AdminDashboard />,
          },
          {
            path: "profile",
            element: <AdminProfile />,
          },
          {
            path: "change-password",
            element: <AdminChangePassword />,
          },
          {
            path: "sliders",
            element: <AdminSliders />,
          },
          {
            path: "categories",
            element: <AdminCategories />,
          },
          {
            path: "brands",
            element: <AdminBrands />,
          },
          {
            path: "colors",
            element: <AdminColors />,
          },
          {
            path: "delivery-addresses",
            element: <AdminDeliveryAddresses />,
          },
          {
            path: "vouchers",
            element: <AdminVouchers />,
          },
          {
            path: "products",
            element: <AdminProducts />,
          },
          {
            path: "products/create",
            element: <AdminProductForm mode="create" />,
          },
          {
            path: "products/:id/edit",
            element: <AdminProductForm mode="edit" />,
          },
          {
            path: "reviews",
            element: <AdminReviews />,
          },
          {
            path: "orders",
            element: <AdminOrders />,
          },
          {
            path: "booking",
            element: <AdminBooking />,
          },
          {
            path: "career",
            element: <AdminCareer />,
          },
          {
            path: "specialbooking",
            element: <AdminSpecialBooking />,
          },
          {
            path: "consent",
            element: <AdminConsent />,
          },
          {
            path: "consent/:id",
            element: <AdminViewConsent />,
          },
          {
            path: "service",
            element: <AdminService />,
          },
          {
            path: "service/create",
            element: <AdminServiceForm mode="create" />,
          },
          {
            path: "service/:id/edit",
            element: <AdminServiceForm mode="edit" />,
          },

          {
            path: "servicePricing/:id",
            element: <AdminServicePricing />,
          },
          {
            path: "special",
            element: <AdminSpecial />,
          },

          {
            path: "users/users",
            element: <AdminUsersUsers />,
          },

          {
            path: "users/admins",
            element: <AdminUsersAdmins />,
          },
          {
            path: "misc/site-setting",
            element: <AdminSiteSetting />,
          },
          {
            path: "misc/contact-messages",
            element: <AdminContactMessages />,
          },
          {
            path: "misc/subscriber",
            element: <AdminSubscriber />,
          },
          {
            path: "misc/gallery",
            element: <AdminGallery />,
          },
          {
            path: "misc/Location",
            element: <AdminLocation />,
          },
          {
            path: "misc/Location/create",
            element: <AdminLocationForm  mode="create"/>,
          },
          {
            path: "misc/:id/edit",
            element: <AdminLocationForm  mode="edit"/>,
          },
          {
            path: "misc/email-templates",
            element: <AdminEmailTemplates />,
          },
          {
            path: "misc/home-ads",
            element: <AdminHomepageAds />,
          },
          {
            path: "misc/email-templates/:slug",
            element: <AdminEmailTemplate />,
          },
    
          {
            path: "misc/pages",
            element: <AdminPages />,
          },
          {
            path: "misc/pages/:slug",
            element: <AdminPage />,
          },
          {
            path: "misc/faq/:id",
            element: <AdminFaqs />,
          },
          {
            path: "misc/faqGroup",
            element: <AdminFaqGroup />,
          },
          {
            path: "blogs",
            element: <AdminBlogs />,
          },
          {
            path: "blogs/create",
            element: <AdminBlogForm mode="create" />,
          },
          {
            path: "blogs/:id/edit",
            element: <AdminBlogForm mode="edit" />,
          },
       
          {
            path: "about/teams",
            element: <AdminTeams />,
          },
          {
            path: "about/testimonials",
            element: <AdminTestimonials />,
          },
        ],
      },

      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
